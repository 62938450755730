import { useEffect, useState, useMemo } from "react";
import Highlight from "react-highlight.js";
import jwt from "jsonwebtoken";
import "./App.css";

const localStorageKey = "paragon-jwt-debugger-state";
const defaultState = { expirationTime: "3600" };

function gracefullyLoadFromStore() {
  try {
    const store = JSON.parse(localStorage.getItem(localStorageKey));
    return store;
  } catch {
    return defaultState;
  }
}

function storeValues(formValues) {
  localStorage.setItem(localStorageKey, JSON.stringify(formValues));
}

function copyToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.log(err);
  }
  document.body.removeChild(textArea);
}

// https://usehooks.com/useDebounce/
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

function App() {
  const [formValues, setFormValues] = useState(
    gracefullyLoadFromStore() || defaultState
  );
  const [jwtValue, setJwtValue] = useState("");
  const debouncedFormValues = useDebounce(formValues, 500);

  const jwtContents = useMemo(
    () => ({
      sub: debouncedFormValues.userId,
      iat: Math.floor(Date.now() / 1000),
      exp:
        Math.floor(Date.now() / 1000) +
        Number(debouncedFormValues.expirationTime),
    }),
    [debouncedFormValues]
  );

  useEffect(() => {
    storeValues(debouncedFormValues);
    try {
      setJwtValue(
        jwt.sign(jwtContents, debouncedFormValues.signingKey, {
          algorithm: "RS256",
        })
      );
    } catch {
      setJwtValue("<error signing JWT>");
    }
  }, [debouncedFormValues, jwtContents]);

  function onChange(key) {
    return (event) => {
      if (key === "signingKey") {
        setFormValues((values) => ({
          ...values,
          [key]: event.target.value.replace(/\\n/g, "\n"),
        }));
      } else {
        setFormValues((values) => ({ ...values, [key]: event.target.value }));
      }
    };
  }

  const authenticateCodeSample = `// Don't use this in production!
paragon.authenticate(
  '${formValues.projectId}',
  '${jwtValue}'
)`;

  return (
    <div className="App max-w-full mx-auto text-text font-medium rounded-md">
      <div className="flex justify-between">
        <div className="border-border border-r w-1/2 px-10 pt-10">
          <label className="block mb-10">
            <p className="font-semibold mb-1">Project ID</p>
            <p className="text-gray text-sm mb-5">
              Your project ID is the UUID in your Paragon Dashboard, i.e.
              https://dashboard.useparagon.com/connect/projects/
              <span className="text-text">
                db06d291-ba2c-41c5-9a12-9362abfd6228
              </span>
              .
            </p>
            <input
              className="input w-full"
              name="projectId"
              placeholder="db06d291-ba2c-41c5-9a12-9362abfd6228"
              value={formValues.projectId}
              onChange={onChange("projectId")}
            ></input>
          </label>
          <label className="block mb-10">
            <p className="font-semibold mb-1">Signing Key</p>
            <p className="text-gray text-sm mb-5">
              You can paste your signing key or upload the contents here.
            </p>
            <div className="flex flex-row justify-between items-center">
              <textarea
                className="input flex-1 h-20"
                name="signingKey"
                placeholder="---BEGIN PRIVATE KEY---"
                onChange={onChange("signingKey")}
                value={formValues.signingKey}
              ></textarea>
              <p className="mx-5 text-gray">&mdash; or &mdash;</p>
              <input
                className="flex-1"
                type="file"
                accept=".txt"
                onChange={(event) => {
                  const [file] = event.target.files;
                  const reader = new FileReader();
                  reader.addEventListener("load", (event) => {
                    onChange("signingKey")({
                      target: { value: event.target.result },
                    });
                  });
                  reader.readAsText(file);
                }}
              />
            </div>
          </label>
          <div className="mb-10">
            <p className="font-semibold mb-1">Token Details</p>
            <p className="text-gray text-sm mb-5">
              These details become the required <code>sub</code> and{" "}
              <code>exp</code> claims in the JWT. More information about how to
              encode these in the{" "}
              <a
                className="text-blue underline"
                href="https://docs.useparagon.com/getting-started/installing-the-connect-sdk#setup-with-your-own-authentication-backend"
                target="_blank"
                rel="noreferrer"
              >
                SDK documentation.
              </a>
            </p>
            <div className="flex flex-row items-center">
              <label className="text-sm font-semibold mr-8">
                User ID (<code>sub</code>)
                <input
                  className="input ml-3"
                  type="text"
                  placeholder="1234567890"
                  onChange={onChange("userId")}
                  value={formValues.userId}
                />
              </label>
              <label className="text-sm font-semibold">
                Expires In (<code>exp</code>)
                <select
                  className="input ml-3"
                  onChange={onChange("expirationTime")}
                  value={formValues.expirationTime}
                >
                  <option value="3600">1 hour</option>
                  <option value="7200">2 hours</option>
                  <option value="18000">5 hours</option>
                  <option value="36000">10 hours</option>
                  <option value="86400">24 hours</option>
                </select>
              </label>
            </div>
          </div>
        </div>
        <div className="w-1/2 px-10 pt-10">
          <div className="mb-10">
            <p className="font-semibold mb-1">Your Code</p>
            <p className="text-gray text-sm mb-5">
              This is an example call to{" "}
              <code className="text-text">paragon.authenticate</code> that you
              can use for testing in your code, as long as the token is valid.
            </p>
            <div className="flex items-start w-full">
              <Highlight
                className="flex-1 mr-2 text-sm w-10/12"
                language="javascript"
              >
                {authenticateCodeSample}
              </Highlight>
              <button
                className="flex-none rounded-sm bg-hover text-gray border-b-2 border-border border-opacity-50 px-3 py-2 font-semibold text-sm"
                onClick={() => copyToClipboard(authenticateCodeSample)}
              >
                Copy Code
              </button>
            </div>
          </div>
          <div className="mb-10">
            <p className="font-semibold mb-1">Unsigned JWT Contents</p>
            <p className="text-gray text-sm mb-5">
              Given the options you specified above, this is the JWT that needs
              to be signed.
            </p>
            <div className="flex items-start">
              <Highlight className="flex-1 mr-2 text-sm" language="javascript">
                {JSON.stringify(jwtContents, null, 2)}
              </Highlight>
            </div>
          </div>
          <div className="mb-10">
            <p className="font-semibold mb-1">
              Paragon User Token / Signed JWT
            </p>
            <p className="text-gray text-sm mb-5">
              You can use this token to authenticate with the SDK or to interact
              with the Connect API.
            </p>
            <div className="flex items-start w-full">
              <Highlight className="flex-1 mr-2 text-sm w-10/12">
                {jwtValue}
              </Highlight>
              <button
                className="flex-none rounded-sm bg-hover text-gray border-b-2 border-border border-opacity-50 px-3 py-2 font-semibold text-sm"
                onClick={() => copyToClipboard(jwtValue)}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
